<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				批量创建子分类
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回分类列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="GoodsCategory != null && GoodsCategory.Id != 0">
					<span>父分类ID</span>
					<em>
						{{GoodsCategory.Id}}
					</em>
				</li>
				
				<li>
					<span>父分类名</span>
					<em  v-if="GoodsCategory != null">
						{{GoodsCategory.Name}}
					</em>
				</li>
				
				<li>
					<span>子分类名称</span>
					<em>
						<el-input type="textarea" v-model="ChildrenName"></el-input>
						<p style="color: rgba(0,0,0,0.4);font-size: 0.9rem;">每个分类用逗号隔开</p>
					</em>
				</li>
				
				
				
				<li>
					<span></span>
					<em>
						<el-button  @click="AddChildren()" type="danger">批量创建子分类</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'GoodsCategoryInfo',
	  props: {
	  },
	  data() {
	      return {
			  GoodsCategory:null,
			  FatherList:[],
			  ChildrenName:'',
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少分类ID，页面无法工作')
			return
		}
		this.GetGoodsCategory(this.$route.params.Id)
	  },
	  methods:{
		  InitGoodsCategory(){
			this.GoodsCategory = {
				Id:'',
				Name:'',
				Status:50,
				FatherId:'',
				Icon:'',
				SnCatId:'',
				JdCatId:'',
				Level:1
			}
		  },
		    GetGoodsCategory(_id){
		  		let that = this
		  		let data = {Service:'Goods',Class: 'GoodsCategory',Action: 'Get',Id:_id,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.GoodsCategory = res.Data
		  			
		  		})
		    },
			AddChildren(){
				let names = this.ChildrenName.replace("，",",")
				let data = {Service:'Goods',Class: 'GoodsCategory',Action: 'AddChildren',FatherId:this.GoodsCategory.Id,ChildrenName:names}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.$Jump('/do/goods/goods_category_list')
					}
				})
			},
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Brand .List{
	margin-top: 20px;	
}
.Brand .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Brand .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Brand .List .One li  em{
	flex: 1;
}
.Brand .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}


.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }

</style>
